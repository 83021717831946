import { type DeliveryAddress } from "@koala/sdk";
import { nanoid } from "nanoid";
import { Component } from "react";
import { type ConnectedProps, connect } from "react-redux";
import Radar from "radar-sdk-js";
import type AutocompleteUI from "radar-sdk-js/dist/ui/autocomplete";
import { autocompleteInputId } from "./autocomplete-input-id";
import {
  StyledAutocompleteInstructions,
  StyledInputResetButton,
} from "./styles";
import { addressValidation } from "./validation";
import StringAccessor from "@/components/cmsConfig/stringAccessor";
import { SecureField } from "@/components/uielements/form-fields";
import { StyledLabel } from "@/components/uielements/label";
import { StyledRadarAddressSearchInputWrapper } from "@/components/uielements/searchInput";
import { K_ANALYTICS_EVENTS, LOG_EVENTS } from "@/constants/events";
import { DELIVERY_TIME_WANTED_MODES } from "@/constants/global";
import { StyledLoader } from "@/features/handoff/time-picker/styles";
import { locationsActions } from "@/redux/locations/actions";
import { type RootState } from "@/types/app";
import * as ErrorReporter from "@/utils/errorReporter";
import { fireKAnalyticsEvent } from "@/utils/koalaAnalytics";
import { safelyGetString } from "@/utils/stringHelpers";
// Radar
import "radar-sdk-js/dist/radar.css";
import { RADAR_MAPS_CONFIG } from "@/utils/radarMaps";

interface Props extends ReduxProps {
  apiKey: string;
  loading: boolean;
  checkOrSearchDeliveryCoverage: (address: DeliveryAddress) => void;
  formValues?: Record<string, string>;
}

interface State {
  address: DeliveryAddress | null;
  searchValue: string;
  missingAddressFields: string[];
}

class RadarAutocomplete extends Component<Props, State> {
  // We need to generate unique input id for each Radar component
  // as there are cases where we could have more than one instance of GoogleAutocomplete component:
  // Delivery Search on Locators page and Opened Delivery Form Modal
  generatedInputId = `${autocompleteInputId}-${nanoid()}`;

  state = {
    address: null,
    searchValue: "",
    missingAddressFields: [],
  };

  async componentDidMount() {
    // Clear any previous errors
    this.props.clearDeliverySearchErrors();

    try {
      this.initAutocomplete();
    } catch (error) {
      ErrorReporter.captureException(error);
      console.error(error);
    }
  }

  autocomplete: AutocompleteUI | null = null;

  initAutocomplete = () => {
    const { strings } = this.props;

    if (this.autocomplete) {
      // Session already started
      return;
    }

    Radar.initialize(RADAR_MAPS_CONFIG.sharableKey);
    this.autocomplete = Radar.ui.autocomplete({
      container: this.generatedInputId,
      width: "100%",
      maxHeight: "300px",
      placeholder: safelyGetString(
        strings,
        "locations.delivery_search_placeholder"
      ),
      limit: 5,
      minCharacters: 2,
      onSelection: (address) => {
        const { formValues } = this.props;

        // Set address in the format we want on state
        this.setState(
          {
            // @ts-expect-error `address` is mistyped.
            address: {
              time_wanted:
                formValues?.time_wanted ?? DELIVERY_TIME_WANTED_MODES.ASAP,
              street_address: address.addressLabel,
              city:
                address.city ??
                address.borough ??
                address.neighborhood ??
                address.county ??
                "",
              state: address.state,
              zip_code: address.postalCode ?? "",
            },
          },
          () => {
            /** @TODO ensure that `address` isn't null. */
            // @ts-expect-error
            this.handleSubmitAddress(this.state.address);
          }
        );
      },
    });
  };

  handleSubmitAddress = (address: DeliveryAddress) => {
    const { showDeliveryAddressInvalidError, checkOrSearchDeliveryCoverage } =
      this.props;

    const validation = address && addressValidation(address);

    // Throw error if missing required address fields
    if (Object.keys(validation).length > 0) {
      this.setState({ missingAddressFields: Object.keys(validation) });
      showDeliveryAddressInvalidError();
      return;
    }

    // This has been extended to accept both API calls, depending on where it's used
    checkOrSearchDeliveryCoverage(address);
  };

  clearInputField = () => {
    const divWrapper = document.getElementById(this.generatedInputId)!;
    const element = divWrapper && divWrapper.getElementsByTagName("input")[0];
    element.value = "";
    this.props.clearDeliverySearchErrors();
    this.setState({ searchValue: "" });
  };

  renderInputError = () => {
    const { invalidDeliverySearchAddress, googleAddressNotSelected } =
      this.props;
    const { missingAddressFields } = this.state;
    const formattedMissingAddressFields = missingAddressFields
      .join(", ")
      .replace(/_/g, " ");

    // User enters incomplete address, e.g. Brooklyn, NY
    if (invalidDeliverySearchAddress) {
      return (
        missingAddressFields.length > 0 && (
          <StringAccessor
            accessor="locations.incomplete_address_text"
            html={true}
            dataObj={{ missingAddressFields: formattedMissingAddressFields }}
          />
        )
      );
    }

    if (googleAddressNotSelected) {
      // KA event
      fireKAnalyticsEvent(K_ANALYTICS_EVENTS.LOG, {
        name: LOG_EVENTS.GOOGLE_ADDRESS_NOT_SELECTED,
        details: "Delivery Search",
      });

      return (
        // User presses enter without selecting an address from the dropdown
        <StringAccessor
          accessor="locations.google_address_not_selected_text"
          html={true}
        />
      );
    }
  };

  render() {
    const {
      loading,
      invalidDeliverySearchAddress,
      noDeliveryLocationsFound,
      googleAddressNotSelected,
    } = this.props;

    const inputId = "delivery-address-autocomplete-instructions";

    return (
      <>
        <div>
          <StyledLabel
            as="span"
            id="autocomplete-instructions"
            visuallyHidden={true}
          >
            Start typing your address, key down when you find your address, and
            then press enter
          </StyledLabel>
          <SecureField
            fieldType={inputId}
            label="Delivery Address"
            // @ts-expect-error
            error={this.renderInputError()}
            aria-describedby={inputId}
          >
            <StyledRadarAddressSearchInputWrapper
              id={this.generatedInputId}
              padding="0 25px 0 35px"
            />

            {loading && !noDeliveryLocationsFound && (
              <StyledLoader right={20} top={10} />
            )}

            {(noDeliveryLocationsFound ||
              invalidDeliverySearchAddress ||
              googleAddressNotSelected) && (
              <StyledInputResetButton
                aria-label="Clear address"
                onClick={this.clearInputField}
              >
                &times;
              </StyledInputResetButton>
            )}
          </SecureField>
        </div>

        {/* No Delivery locations returned from API  */}
        {noDeliveryLocationsFound &&
          !invalidDeliverySearchAddress &&
          !loading && (
            <StringAccessor
              tag={StyledAutocompleteInstructions}
              accessor="locations.delivery_search_no_results_text"
              html={true}
            />
          )}

        {/* Delivery Search Instructional Text */}
        {!noDeliveryLocationsFound &&
          !invalidDeliverySearchAddress &&
          !googleAddressNotSelected && (
            <StringAccessor
              tag={StyledAutocompleteInstructions}
              accessor="locations.delivery_search_instructional_text"
              html={true}
            />
          )}
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  strings: state.app.cmsConfig.strings,
  invalidDeliverySearchAddress:
    state.app.locations.invalidDeliverySearchAddress,
  noDeliveryLocationsFound: state.app.locations.noDeliveryLocationsFound,
  googleAddressNotSelected: state.app.locations.googleAddressNotSelected,
});

const mapDispatchToProps = {
  showDeliveryAddressInvalidError:
    locationsActions.showDeliveryAddressInvalidError,
  clearDeliverySearchErrors: locationsActions.clearDeliverySearchErrors,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;
export default connector(RadarAutocomplete);
